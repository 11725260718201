import { useMemo } from 'react';

export default (items, excludedItemId) => {
  const remainingItems = useMemo(
    () => items.filter(({ id }) => id !== excludedItemId),
    [
      items,
      excludedItemId,
    ]
  );

  return [
    remainingItems,
    remainingItems.length,
  ];
};
