import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import mediaQueries from '@utils/media-queries';
import {
  CommonScripts,
  Content,
  NextPageBox,
  Sidebar,
} from '@components';

import { parseContent } from '@utils/helpers';

// hamburger
import { useCallback } from 'react';
import {
  headerStairsMobile,
  homeCollectionsStairs,
  insightsGridConfig,
} from '@utils/grid';
import { Container } from 'react-awesome-styled-grid';
import { ThemeProvider } from 'styled-components';
import api from '@utils/api';
import HamburgerMenu from '@components/HamburgerMenu/HamburgerMenu';
import { debounce } from '@utils/debounce';
import { MenuContext } from '../components/HamburgerMenu/MenuContext';
import useResizeObserver from 'use-resize-observer/polyfilled';
import useInsightsPosts, {
  DEFAULT_SORT_OPTION,
  SORT_OPTIONS,
} from '@services/useInsightsPosts';
import useRemainingItems from '../utils/useRemainingItems';

const {
  small,
  large,
  xlarge,
} = mediaQueries;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;

  @media ${xlarge} {
    font-size: 13px;
  }

  @media ${large} {
    font-size: 12px;
  }

  @media ${small} {
    overflow: scroll;
    flex-direction: column;
    justify-content: flex-end;
  }
`;

const App = ({
  location,
  pageContext: {
    content,
    stepSlug,
  },
}) => {
  const {
    chat_interaction_id: driftId,
    cta_text: cta,
    items,
    slug,
    use_images: hasTags,
  } = parseContent(content);

  let currentIndex = location.state?.content?.index || 0;
  const urlParams = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : null;
  const [currentItem] = items.filter((item) => item.innerSlug === stepSlug);
  const isVisited = typeof sessionStorage === 'undefined' ? false : sessionStorage.getItem('isVisited');

  if (currentItem) {
    currentIndex = currentItem.index;
  }

  const [
    selectedTag,
    selectTag,
  ] = useState(urlParams?.get('tag') || null);

  const [
    showMore,
    setShowMore,
  ] = useState(urlParams ? JSON.parse(urlParams?.get('more')) : false);

  const [
    filteredItems,
    setFilteredItems,
  ] = useState(selectedTag ? items.filter((item) => item.tags.includes(selectedTag)) : items);

  const [
    navigationVisibility,
    setNavigationVisibility,
  ] = useState(true);

  const [
    isChatActive,
    setChatActive,
  ] = useState(false);

  const filterTags = (tag) => {
    if (!tag || selectedTag === tag) {
      setFilteredItems(items);
      selectTag(null);

      if (typeof window !== 'undefined') {
        window.history.replaceState(null, null, window.location.pathname);
      }
    } else {
      const filtered = items.filter((item) => item.tags.includes(tag)).map(
        (item, index) => ({
          ...item,
          index,
        })
      );

      const [firstFilteredItem] = filtered;

      setFilteredItems(filtered);
      selectTag(tag);
      navigate(
        `/${slug}/${firstFilteredItem.innerSlug}?tag=${tag}`,
        {
          state: {
            content: firstFilteredItem,
          },
        }
      );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setNavigationVisibility(false);
      sessionStorage.setItem('isVisited', true);
    }, isVisited ? 1000 : 3000);
  }, []);


  // hamburger menu

  const getSrcByType = (data) => {
    return ""
    switch (data.content_type.medium_type) {
    default:
    case 'url': {
      return data.external_link;
    }
    case 'video': {
      return data.file_video;
    }
    case 'pdf': {
      return data.file_pdf;
    }
    case 'blog': {
      return `https://www.merixstudio.com/blog/${data.blog.slug}`;
    }
    }
  };

  const {
    menu_items: menuItems,
    footer_image: footerImage,
    social_media_icons: socialItems,
    chat_interaction_id: chatInteractionId,
    cta_text: chatInteractionText,
    reviews,
    id,
  } = content;

  // Default clutch data if live data is not provided
  const clutch = {
    "rating": 4.8,
    "clients": 80,
    "first_text": "World's #2 Web & Mobile App Development Company",
    "second_text": "#1 Python & Django Developers",
    "reviews_url": "https://clutch.co/profile/merixstudio#reviews"
  }

  const parsedClutch = {
    ...clutch,
    firstText: clutch.first_text,
    reviewsUrl: clutch.reviews_url,
    secondText: clutch.second_text,
  };

  const contentData = {
    src: getSrcByType(content),
    title: content.title,
    type: "", //content.content_type.medium_type,
    video: {
      desc: content.video_description,
      dur: content.video_duration,
      fileUrl: content.file_video,
      thumb: content.video_thumbnail,
      title: content.video_title,
      upDate: content.video_upload_date,
    } || null,
  };

  const meta = {
    description: content.meta_description,
    og_description: content.meta_description,
    og_image: content.image,
    title: content.meta_title,
  };

  const initialFilters = {
  };

  const {
    ref, width: windowWidth = 0,
  } = useResizeObserver();

  const pageSize = 9;
  const [
    postsState,
    fetchNextPage,
  ] = useInsightsPosts(initialFilters, { pageSize });

  const [
    visibleItems,
    visibleItemsCount,
  ] = useRemainingItems(postsState.data, content.id);

  useEffect(() => {
    api.post('/content-insights/increase-content-visit-count', {
      content_id: id,
    });
  }, []);

  const shouldDisableSidePaddings = contentData.type === 'pdf' || contentData.type === 'blog';

  const shouldDisableIndexing = contentData.type === 'link' || contentData.type === 'blog';

  const checkScrollYAmount = () => window.scrollY >= 0;

  const [
    resizeClass,
    setResizeClass,
  ] = useState('');

  const addResizeClass = () => setResizeClass('resizing');
  const removeResizeClass = debounce(() => {
    setResizeClass('');
  }, 1000);

  const handleResize = () => {
    addResizeClass();
    removeResizeClass();
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const setDefaultOverflowValue = useCallback(() => {
    document.getElementsByTagName('html')[0].style.overflowY = 'auto';
  }, []);

  const [
    revealMenuButton,
    setRevealMenuButton,
  ] = useState(true);

  const [
    isOpenedFromNavigation,
    setOpenedFromNavigation,
  ] = useState(false);

  const showMenuBtn = useCallback(() => {
    if (isOpenedFromNavigation) {
      setRevealMenuButton(checkScrollYAmount());
      setDefaultOverflowValue();
    } else {
      setRevealMenuButton(checkScrollYAmount());
      setDefaultOverflowValue();
    }
  }, [setDefaultOverflowValue]);

  useEffect(() => {
    const onScroll = () => showMenuBtn();

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [showMenuBtn]);

  const [
    icon,
    setIcon,
  ] = useState('menu');
  const switchIcon = () => {
    setIcon((last) => (last === 'close' ? 'menu' : 'close'));
  };

  const [
    menuVisible,
    setMenuVisible,
  ] = useState(false);
  const [
    toggleMenu,
    setToggleMenu,
  ] = useState(false);

  const switchToggle = () => {
    setTimeout(() => {
      setToggleMenu(() => !menuVisible);
    }, 100);
  };

  const [
    isExpanded,
    setExpanded,
  ] = useState(false);

  const [
    activeElement,
    setActiveElement,
  ] = useState('');

  const toggleClick = (attr) => {
    setExpanded(true);
    setActiveElement(attr);
  };

  const closeExpand = () => {
    if (checkScrollYAmount()) {
      setRevealMenuButton(true);
      setOpenedFromNavigation(false);
    }
    setExpanded(false);
    setActiveElement(null);
  };

  const setOverlayValue = () => {
    document.getElementsByTagName('html')[0].style.overflowY = toggleMenu ? 'auto' : 'hidden';
  };

  const handleMenuClick = () => {
    console.log("click")
    if (!checkScrollYAmount()) {
      setRevealMenuButton(false);
    } else {
      setRevealMenuButton(true);
    }
    setMenuVisible(!menuVisible);
    switchIcon();
    switchToggle();
    setOverlayValue();
  };

  const manageMenuButtonState = () => {
    if (checkScrollYAmount()) {
      setRevealMenuButton(false);
      setOpenedFromNavigation(false);
    }
    closeExpand();
    handleMenuClick();
  };


  // hamburger menu

  return (
    <Wrapper>
      <CommonScripts />
      <Sidebar
        allItems={items}
        isChatActive={isChatActive}
        content={filteredItems}
        cta={cta}
        driftId={driftId}
        currentIndex={currentIndex}
        hasTags={hasTags}
        filterTags={filterTags}
        navigationVisibility={navigationVisibility}
        selectedTag={selectedTag}
        setChatActive={setChatActive}
        setNavigationVisibility={setNavigationVisibility}
        setShowMore={setShowMore}
        showMore={showMore}
        slug={slug}
      />
      <Content
        data={items[currentIndex] || filteredItems[0]}
        hasTags={hasTags}
      />
      <MenuContext.Provider
        value={{
          isExpanded,
          isVisible: toggleMenu,
          setExpanded,
        }}
      >
        <HamburgerMenu
          activeElement={activeElement}
          closeExpand={closeExpand}
          icon={icon}
          isExpanded={isExpanded}
          manageMenuButtonState={manageMenuButtonState}
          showMenuBtn={revealMenuButton}
          toggleClick={toggleClick}
          toggleMenu={toggleMenu}
          chatId={driftId}
        />
      </MenuContext.Provider>
      <NextPageBox
        data={items}
        filterTags={filterTags}
        hasTags={hasTags}
        currentIndex={currentIndex}
        selectedTag={selectedTag}
        slug={slug}
        text="Next"
      />
    </Wrapper>
  );
};

App.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.object,
  }).isRequired,
  pageContext: PropTypes.shape({
    content: PropTypes.object,
    stepSlug: PropTypes.string,
  }).isRequired,
};

export default App;
